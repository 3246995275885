<template>
    <div>
        <el-dialog title="设置头像" :visible.sync="dialogVisible" width="797px" :close-on-click-modal=false>
            <div class="head-main">
                <div class="tit">系统头像</div>
                <div class="head-list">
                    <div class="head-item" :class="{active:imageUrl==item}" v-for="(item,index) in list.slice(0,8)" :key="index" @click="changeAvatar(item)">
                        <img :src="item" alt="">
                    </div>
                </div>
                <div class="tit">自定义头像</div>
                <div class="head-upload">
                    <div class="img" :class="{active:imageUrl==item}" v-for="(item,index) in list.slice(8)" :key="index" @click="changeAvatar(item)">
                        <img :src="item" alt="">
                    </div>
                    <el-upload class="avatar-uploader" action="/member/tts_list/upload_avatar" :headers="headers" accept="image/jpg,image/jpeg,image/png" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            headers: {
                'Authorization': this.$store.state.token
            },
            dialogVisible: false,
            list: [
                'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t1.png',
                'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t2.png',
                'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t3.png',
                'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t4.png',
                'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t5.png',
                'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t6.png',
                'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t7.png',
                'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t8.png',
            ],
            imageUrl: '',
        };
    },
    created() {

    },
    methods: {
        changeAvatar(url) {
            this.imageUrl = url
        },
        confirm() {
            if (this.imageUrl == '') {
                this.$errMsg('请选择头像')
                return
            } else {
                this.$emit('editAvatar', this.imageUrl)
            }
            this.dialogVisible = false
        },
        open() {
            this.dialogVisible = true
        },
        handleAvatarSuccess(res) {
            this.imageUrl = res.result.url
            this.list.push(res.result.url)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG/JEPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        close() {
            this.imageUr = ''
        },
    },
};
</script>

<style scoped lang="scss">
@keyframes infinite-loop {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.head-main {
    .tit {
        font-size: 14px;
        color: #333333;
        padding: 20px 0 14px 0;
    }
    .head-list {
        width: 757px;
        height: 100px;
        background: #f8f8f8;
        padding: 14px 20px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .head-item {
            width: 76px;
            height: 76px;
            cursor: pointer;
            border-radius: 2px;
            border: 2px solid #f8f8f8;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .head-item.active {
            border: 2px solid #696efa;
        }
    }
    .head-upload {
        width: 757px;
        background: #f8f8f8;
        padding: 14px 20px;
        display: flex;
        flex-wrap: wrap;
        .img {
            width: 76px;
            height: 76px;
            margin: 0 10px 10px 0;
            border-radius: 2px;
            border: 2px solid #f8f8f8;
            cursor: pointer;
            img {
                width: 72px;
                height: 72px;
                border-radius: 50%;
            }
        }
        .img.active {
            border: 2px solid #696efa;
        }
    }
}
.avatar-uploader {
    width: 76px;
    height: 76px;
    border: 1px dashed #e0e0e2;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #f5f7fb;
}

.avatar-uploader-icon {
    font-size: 16px;
    color: #8c939d;
    width: 76px;
    height: 76px;
    line-height: 76px;
    text-align: center;
}
</style>
