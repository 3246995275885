<template>
  <div>
    <el-upload action="#" accept=".zip" :show-file-list="false" :http-request="uploadBtn">
      <el-button size="small" :loading="uploadLoading" type="primary">上传语料</el-button>
    </el-upload>
  </div>
</template>
<script>
  export default {
    props: {

    },
    data() {
      return {
        uploadLoading: false,
        name: "",
      }
    },
    methods: {
      uploadBtn(item) {
        var that = this
        var file = item.file
        const filmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const isZip = filmsg === 'zip'
        const isLt500M = file.size / 1024 / 1024 < 500;
        if (!isZip) {
          that.$message.error("只能上传zip格式压缩文件!");
          return
        }
        if (!isLt500M) {
          that.$message.error("上传文件大小不能超过 500MB!");
          return
        }
        that.name = file.name
        that.uploadLoading = true
        let newFile = new FormData()
        newFile.append('file', item.file)
        that.$publicApi.uploadAudio(newFile).then(res => {
          that.uploadLoading = false
          if (res.code == 1000) {
            that.$emit('uploadSuccess', res.result.short_url, that.name)
          } else {
            that.$message.error(res.message);
          }
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
</style>
